import React from 'react'
import { Link } from 'gatsby'
const styles = require('./navigation.module.css')

const Component: React.FC<{}> = () => {
  return (
    <nav role="navigation">
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/">Home</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/blog/">Blog</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/tools/json-formatter/">JSON整形ツール</Link>
      </li>
    </ul>
  </nav>
  )
}

export default Component